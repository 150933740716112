import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { hexToRGB } from '../../../../utils/HexToRgba'

export function CompanyNameRender(data: any) {
  return (
    <div className='py-3'>
      <p className='m-0 '>{data.data.companyName}</p>
    </div>
  )
}
export function CompanyIdRender(data: any) {
  return (
    <div className='py-3'>
      <p className='m-0'>{data.data.companyId}</p>
    </div>
  )
}

export function CompanyTypeRender(data: any) {
  return (
    <div className='py-3'>
      {data.data?.companyType === 'Hissa' && (
        <span
          className={`badge fs-8 fw-semibold  px-6`}
          style={{
            background: hexToRGB(getCSSVariableValue('--kt-donut-' + (0 % 10)), 0.2),
            color: hexToRGB(getCSSVariableValue('--kt-donut-' + (0 % 10)), 1),
          }}
        >
          {data.data?.companyType}
        </span>
      )}
      {data.data?.companyType === 'Manual' && (
        <span
          className={`badge fs-8 fw-semibold px-5`}
          style={{
            background: hexToRGB(getCSSVariableValue('--kt-donut-' + (3 % 10)), 0.2),
            color: hexToRGB(getCSSVariableValue('--kt-donut-' + (3 % 10)), 1),
          }}
        >
          {data.data?.companyType}
        </span>
      )}
    </div>
  )
}
