import {
  GetNewHissaCompaniesQuery,
  useGetNewHissaCompaniesQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Select from 'react-select'
import { selectCustomStyles } from '../../../../utils/Select'
import { useEffect, useState } from 'react'
import { getUserInvestors } from '../../../../utils/LocalStorageUtils'
import { InvestorNewCompaniesTable, newCompanyData } from './InvestorCompaniesAGTable'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

function SyncInvestorCompaniesPage() {
  const [selectedInvestorId, setSelectedInvestorId] = useState(-1)
  const investorTypes = getUserInvestors('userInvestors')

  const investorOptions = investorTypes.map((item) => {
    return { label: item.investorName, value: item.investorId }
  })

  const { data, isLoading } = useGetNewHissaCompaniesQuery<GetNewHissaCompaniesQuery, Error>(
    graphqlRequestClient,
    { input: selectedInvestorId },
    { enabled: selectedInvestorId !== -1, cacheTime: 0, staleTime: 0 }
  )

  useEffect(() => {
    if (selectedInvestorId !== -1) {
      const getNewHissaCompaniesQueryKey = useGetNewHissaCompaniesQuery.getKey()
      queryClient.invalidateQueries(getNewHissaCompaniesQueryKey)
    }
  }, [selectedInvestorId])

  return (
    <>
      <div className='col p-9'>
        <div className='card'>
          <h2 className='card-title fw-bold'>Sync Investor Companies</h2>
          <div className='separator' />
          <div className='col-12 col-md-6'>
            <div className='pb-5 pt-5'>
              <h4 className='required'>Select Investor/Fund :</h4>
              <Select
                options={investorOptions}
                styles={selectCustomStyles}
                name='fundType'
                onChange={(e: any) => {
                  setSelectedInvestorId(e.value)
                }}
              />
            </div>
          </div>
        </div>
        {data?.getNewHissaCompaniesForInvestor &&
        data.getNewHissaCompaniesForInvestor?.length > 0 &&
        selectedInvestorId > 0 ? (
          <InvestorNewCompaniesTable
            data={(data?.getNewHissaCompaniesForInvestor as newCompanyData[]) || []}
            selectedInvestorId={selectedInvestorId}
            isExisting={false}
          />
        ) : (
          <>
            <div
              className='card p-9 my-9 h-375px'
              style={{
                boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                border: '1',
                borderColor: '#F1F1F2',
              }}
            >
              {' '}
              {!isLoading && (
                <div className='text-center mt-6'>
                  {data?.getNewHissaCompaniesForInvestor?.length === 0 ? (
                    <>
                      <img
                        alt='no items'
                        src={toAbsoluteUrl('/media/custom-images/emptyRecentCompanies.svg')}
                        className='h-150px my-6 w-200 justify-content-center'
                      />
                      <h4>No new companies were found for the selected investor</h4>
                    </>
                  ) : (
                    <>
                      <img
                        alt='no companies'
                        src={toAbsoluteUrl('/media/icons/duotune/general/empty-watchlist.svg')}
                        className='h-150px my-6 w-200 justify-content-center'
                      />
                      <h4>No Investor/Fund has selected</h4>
                      <div className='fw-semibold fs-6'>
                        Please select Investor/Fund above to view new companies
                      </div>
                    </>
                  )}
                </div>
              )}
              {isLoading && (
                <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
                  <span className='spinner-grow'></span>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default SyncInvestorCompaniesPage
