import { AgGridReact } from 'ag-grid-react'
import { memo, useContext, useMemo, useRef, useState } from 'react'
import { agConfigSideBar, tableCellStyle } from '../../../../../utils/AgGridConfig'
import { ColDef } from 'ag-grid-community'
import {
  CompanyObject,
  GetDealTemplatesQuery,
  InvestmentModellingDetails,
  useGetDealTemplatesQuery,
} from '../../../../../generated/graphql'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { GlobalContext } from '../../../store/context/globalContext'
import { getCurrency } from '../../../../../utils/CurrencyFormatter'
import {
  CurrentSizeRender,
  CurrentValueRender,
  DilutionRender,
  HoldingPercentageRender,
  InvestmentModelActionRender,
  ModelNameRender,
  PostMoneyRender,
} from './ExitModellingAGComponents'
import { useNavigate } from 'react-router-dom'

type TransactionProps = {
  exitModellingData?: InvestmentModellingDetails[]
  hissaCompanyId: string
}

export function ExitModellingAGTable(props: TransactionProps) {
  const gridApi = useRef<any>(null)
  let exitModelData: InvestmentModellingDetails[] =
    (props.exitModellingData as InvestmentModellingDetails[]) || []
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)
  const [showModal, setShowModal] = useState<boolean>(false)
  const navigate = useNavigate()
  const componentsRegistry = useMemo(
    () => ({
      modelName: memo(ModelNameRender),
      currentValue: memo(CurrentValueRender),
      holdingPercentage: memo(HoldingPercentageRender),
      currentSize: memo(CurrentSizeRender),
      dilution: memo(DilutionRender),
    }),
    []
  )

  const showNoRowsOverlay = () => (
    <div className='row justify-content-center  text-center py-8 m-6'>
      <img
        alt='no items'
        src={toAbsoluteUrl('/media/icons/duotune/general/empty-watchlist.svg')}
        className='h-150px my-10 w-350 justify-content-center'
      />
      <p className='fs-base text-muted text-center'>
        No Exit Model found, Get started by adding New Model.
      </p>
    </div>
  )

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ['reset'],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  )

  const [isColumnOpen, setIsColumnOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const openToolPanel = (key: any) => {
    if (key === 'columns') {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key)
        else gridApi?.current?.api?.closeToolPanel()
        setIsColumnOpen((state) => !state)
        setIsFilterOpen(false)
      }
    } else if (key === 'filters') {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key)
        } else gridApi?.current?.api?.closeToolPanel()
        setIsFilterOpen((state) => !state)
        setIsColumnOpen(false)
      }
    }
  }

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: 'Model Name',
        field: 'modelName',
        cellRenderer: 'modelName',
        cellStyle: tableCellStyle,
        pinned: 'left',
        initialWidth: 250,
        sortable: true,
        autoHeight: true,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ['filterMenuTab'],
      },
      // {
      //   headerName: 'Current Value',
      //   field: 'roundDate',
      //   filter: 'agDateColumnFilter',
      //   filterParams: {
      //     comparator: (dateFromFilter: Date, cellValue: any) => {
      //       if (cellValue == null) {
      //         return 0
      //       }
      //       const dateParts = cellValue.split('-')
      //       const day = Number(dateParts[2]?.split('T')[0])
      //       const month = Number(dateParts[1]) - 1
      //       const year = Number(dateParts[0])
      //       const cellDate = new Date(year, month, day)
      //       if (cellDate < dateFromFilter) {
      //         return -1
      //       } else if (cellDate > dateFromFilter) {
      //         return 1
      //       }
      //       return 0
      //     },
      //   },
      //   cellRenderer: 'roundDate',
      //   cellStyle: tableCellStyle,
      //   sortable: true,
      //   menuTabs: ['filterMenuTab'],
      // },
      {
        headerName: 'Current Value',
        field: 'currentValue',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'currentValue',
        cellStyle: tableCellStyle,
        initialWidth: 200,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipCurrentValue',
      },
      {
        headerName: 'Current Holding Percentage',
        field: 'holdingPercentage',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'holdingPercentage',
        cellStyle: tableCellStyle,
        initialWidth: 200,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipHoldingPercentage',
      },
      // {
      //   headerName: 'Valuation',
      //   field: 'valuation',
      //   filter: 'agNumberColumnFilter',
      //   cellRenderer: 'valuation',
      //   cellStyle: tableCellStyle,
      //   initialWidth: 200,
      //   sortable: true,
      //   menuTabs: ['filterMenuTab'],
      //   tooltipField: 'tooltipValuation',
      // },

      {
        headerName: '',
        field: 'actions',
        pinned: 'right',
        hide: false,
        width: 60,
        maxWidth: 60,
        filter: false,
        menuTabs: [],
        colId: 'action-column',
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: {
          'padding-left': '9px',
          'padding-right': '4px',
          'padding-top': '3px',
          'line-height': '20px',
        },
        resizable: false,
        sortable: false,
        cellRenderer: InvestmentModelActionRender,
      },
    ],
    [exitModelData, userCurrencyFormat]
  )

  const rowData = useMemo(
    () =>
      exitModelData?.map((modelData: InvestmentModellingDetails) => {
        const exitData = modelData.investmentModellingDetails

        return {
          modelId: modelData.modellingDataId,
          modelName: modelData.modelName,
          // roundDate: roundData.roundDate,
          holdingPercentage: Math.abs(exitData?.finalHoldingPercentage || 0),
          tooltipRevenue: Math.abs(exitData?.finalHoldingPercentage || 1).toLocaleString(
            userCurrencyFormat
          ),
          currentValue: Math.abs(exitData?.finalCurrentValue || 0),
          tooltipCurrentValue: Math.abs(exitData?.finalCurrentValue || 1).toLocaleString(
            userCurrencyFormat
          ),
          currentModel: exitData,
          hissaCompanyId: props.hissaCompanyId,
          companyData: '',
          userCurrencyFormat: userCurrencyFormat,
          userCurrency: userCurrency,
        }
      }),
    [exitModelData, userCurrencyFormat]
  )

  return (
    <div
      className='card p-0 h-500px overflow-auto mt-4'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div className='d-flex flex-row border-0 p-9'>
        <div className='card-title align-items-end flex-column'>
          <h1 className='m-0'>Exit Modelling</h1>
          <span className='text-muted'></span>
        </div>
        <div className='col d-flex flex-row justify-content-end'>
          <button
            type='submit'
            onClick={() => setShowModal(!showModal)}
            className='px-4 rounded border border-0 border-primary bg-primary h-38px fw-normal ms-4 text-white'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen041.svg'
              className='svg-icon-3 text-white'
            />{' '}
            Add New Model
          </button>
          {showModal && (
            <>{navigate(`/company/exitmodelling/HISSA/${props.hissaCompanyId}/${0}`)}</>
          )}
          <button
            type='submit'
            value={'Columns'}
            onClick={() => openToolPanel('columns')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/layouts/lay004.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Columns
          </button>
          <button
            value={'Filters'}
            onClick={() => openToolPanel('filters')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen031.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Filter
          </button>
        </div>
      </div>
      <div className='card-body w-100 p-9 pt-0 overflow-auto ag-theme-material h-100'>
        <AgGridReact
          sideBar={agConfigSideBar}
          onGridReady={(params) => {
            gridApi.current = params
          }}
          components={componentsRegistry}
          alwaysShowHorizontalScroll
          alwaysMultiSort
          animateRows={true}
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={false}
          enableCharts={true}
          enableRangeSelection={false}
          suppressRowTransform={true}
          suppressCopyRowsToClipboard={true}
          suppressCopySingleCellRanges={true}
          suppressCellFocus={true}
          noRowsOverlayComponent={showNoRowsOverlay}
          suppressMenuHide={false}
          tooltipShowDelay={50}
          tooltipInteraction={true}
          rowClass={'border-right-0 border-left-0 border-dashed'}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
          }
          getRowStyle={(params) => {
            if (params.rowIndex % 2 === 0) {
              return { background: '#f8f8f8' }
            } else {
              return { background: '#ffffff' }
            }
          }}
        ></AgGridReact>
      </div>
    </div>
  )
}
