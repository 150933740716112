import { useNavigate, useParams } from 'react-router-dom'
import {
  AddRecentCompaniesMutation,
  AddRecentCompaniesMutationVariables,
  CompanyDataInput,
  CompanyTypes,
  GetAllExitModellingQuery,
  InvestmentModellingDetails,
  useAddRecentCompaniesMutation,
  useGetAllExitModellingQuery,
  useGetRecentCompaniesQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'
import CompanyErrorPage from '../components/CompanyErrorPage'
import { ExitModellingAGTable } from '../components/ExitModelling/ExitModellingAGTable'

function ExitModellingWrapper() {
  const { companyType, id } = useParams()
  const navigate = useNavigate()

  const compType = companyType as CompanyTypes
  let hissaCompanyId = ''
  let companyId = 0
  compType === CompanyTypes.Hissa ? (hissaCompanyId = id || '') : (companyId = Number(id) || 0)

  const input: CompanyDataInput = {
    type: compType,
    hissaCompanyId: hissaCompanyId,
    id: companyId,
  }

  const mutateAddRecentCompanies = useAddRecentCompaniesMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: AddRecentCompaniesMutation,
        _variables: AddRecentCompaniesMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(useGetRecentCompaniesQuery.getKey())
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  const { data, isLoading, isError } = useGetAllExitModellingQuery<GetAllExitModellingQuery, Error>(
    graphqlRequestClient,
    {
      input: { hissaCompanyId: hissaCompanyId },
    },
    {}
  )

  if (!companyType || !id) {
    navigate('/error/404')
  }

  if (isLoading) {
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )
  }

  // return <ExitModellingPage />

  return (
    <ExitModellingAGTable
      exitModellingData={
        (data?.getAllExitModellingDetails &&
          (data?.getAllExitModellingDetails as InvestmentModellingDetails[])) ||
        ([] as InvestmentModellingDetails[])
      }
      hissaCompanyId={hissaCompanyId}
    />
  )
}

export default ExitModellingWrapper
